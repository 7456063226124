import { FURIOOS_CONTAINER } from './constants';

export const getFurioosContainer = () =>
  document.getElementById(FURIOOS_CONTAINER);

export const hide = (node) => {
  /* eslint-disable no-param-reassign */
  node.style.opacity = 0;
};

export const displayNone = (node) => {
  /* eslint-disable no-param-reassign */
  node.style.display = 'none';
};

export const displayBlock = (node) => {
  /* eslint-disable no-param-reassign */
  node.style.display = 'block';
};

export const show = (node) => {
  /* eslint-disable no-param-reassign */
  node.style.opacity = 1;
};

export const getCurrentDate = () => new Date()?.toGMTString?.();

// /* eslint-disable no-restricted-globals */
// export const getMobileOrientation = () =>
// window.matchMedia('(orientation: landscape)');
