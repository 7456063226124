import React, { useCallback, useEffect, useRef, useState } from 'react';
import delay from 'lodash.delay';
import useSdkMessage from '../hooks/useSdkMessage';
import useSuperuserMode from '../hooks/useSuperuserMode';
import PlayerSingleton from '../player';
import OverlayContainer from '../primitives/OverlayContainer';
import OverlaySlice from '../primitives/OverlaySlice';
import {
  getFurioosContainer,
  show,
  hide,
  displayNone,
  getCurrentDate,
} from '../helpers';
import Fader from '../primitives/Fader';
import Logo from '../primitives/Logo';
import Subtitle from '../primitives/Subtitle';
import Carousel from './Carousel';
import ProgressBar from '../primitives/ProgressBar';
import AudioIcon from '../primitives/AudioIcon';
import RotateYourDeviceIcon from '../primitives/RotateYourDeviceIcon';
import FullscreenIcon from '../primitives/FullscreenIcon';
import PlayButton from '../primitives/PlayButton';
import BubbleSpeech from '../primitives/BubbleSpeech';
import Spinner from '../primitives/Spinner';
import isEmail from '../predicates/isEmail';
import useMobileDetect from '../hooks/useMobileDetect';
import useDeviceOrientation from '../hooks/useDeviceOrientation';
import Alert from '../primitives/Alert';
import InputForm from '../primitives/InputForm';
import {
  NODE_SERVER_URL,
  PROGRESS_BAR_MAX_OUT_TEXT,
  PROGRESS_BAR_TEXTS,
} from '../constants';

/* eslint-disable react/prop-types */
export default function Preloader() {
  const player = PlayerSingleton.getInstance();
  const mediaContainer = getFurioosContainer();

  const isMobile = useMobileDetect();
  const { isLandscape } = useDeviceOrientation();
  const {
    playerError,
    playerReady: prepareEntry,
    playerClosed,
    openLink,
    openLinkTitle,
    openLinkType,
    clearOpenLink,
    fullscreen,
    setFullscreenFunc,
  } = useSdkMessage({ player });

  const isSuperuser = useSuperuserMode();
  const inputRef = useRef(null);
  const checkboxRef = useRef(null);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxFill, setCheckboxFill] = useState('#fff');

  const [mounted, setMounted] = useState(false);
  const [progress, setProgress] = useState(10);
  const [load, setLoad] = useState(false);
  const [emailSent, setEmailSent] = useState(isSuperuser);
  const [appProgress, setAppProgress] = useState(false);
  const [showHeading, setShowHeading] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);

  const showOrientationAlert =
    !playerClosed && playerReady && isMobile && !isLandscape;
  const showSlice =
    (!prepareEntry && !playerReady) || playerClosed || !emailSent;
  const showProgressBar = emailSent && !playerReady && appProgress;
  const showPlayButton = !playerClosed && !playerReady && !appProgress;
  const showCarousel = appProgress && !playerReady;
  const showWelcome = emailSent && !playerReady && prepareEntry;
  const showInputForm =
    !playerClosed && !emailSent && !playerReady && appProgress;

  const sendEmailToSheets = useCallback(async () => {
    const { value } = inputRef.current;
    if (!isEmail(value)) {
      inputRef.current.focus();
      inputRef.current.style.borderColor = 'red';
      return;
    }

    if (!checkbox) {
      setCheckboxFill('red');
      inputRef.current.style.borderColor = 'white';
      return;
    }

    setCheckboxFill('#fff');
    inputRef.current.style.borderColor = 'white';

    setEmailSent(true);
    setTimeout(async () => {
      try {
        await fetch(`${NODE_SERVER_URL}/append-user`, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({ date: getCurrentDate(), email: value }),
        });
      } catch (e) {
        console.log('api did not response');
      }
    }, 0);

    setEmailSent(true);
  }, [inputRef, checkbox, setCheckboxFill, setEmailSent]);

  const startProcess = useCallback(() => {
    if (!load) return;
    try {
      setAppProgress(true);
      player.start();
      if (isMobile) {
        window.scrollTo({ top: 48, behavior: 'smooth' });
        setFullscreenFunc(true);
      }
    } catch (e) {
      if (isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFullscreenFunc(false);
      }
    }
  }, [load, isMobile]);

  const restartProcess = useCallback(() => {
    try {
      window.location.reload(true);
    } catch (e) {
      console.error('could not restart stream', e);
    }
  }, []);

  useEffect(() => {
    delay(() => {
      setShowHeading(true);
    }, 2000);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (playerClosed) {
      hide(mediaContainer);
      displayNone(mediaContainer);
      clearOpenLink();
    }
  }, [playerClosed]);

  useEffect(() => {
    if (!playerReady && appProgress && progress < 100) {
      delay(
        (val) => {
          setProgress(val);
        },
        1500,
        progress + 2.5
      );
    }

    if (playerReady && emailSent) {
      setProgress(100);
      show(mediaContainer);
    }
  }, [appProgress, playerReady, progress, emailSent]);

  useEffect(() => {
    if (prepareEntry && emailSent) {
      delay(
        (val) => {
          setPlayerReady(val);
        },
        3000,
        true
      );
    }
  }, [prepareEntry, emailSent]);

  useEffect(() => {
    player.onLoad(() => {
      setLoad(true);
    });

    player.onAppInstallFail(() => {
      setAppProgress(false);
      setLoad(false);
    });

    player.onSessionStopped(() => {
      setAppProgress(false);
      setLoad(false);
    });
  }, [player]);

  const carouselItems = [
    {
      el: <AudioIcon />,
      title: 'please turn on audio for the best experience',
    },
    {
      title: `please don't reload this page or use 'back' in your browser`,
    },
    {
      title: 'you can freely explore the space or take our guided tour',
    },
  ];

  if (!fullscreen) {
    carouselItems.push({
      el: <FullscreenIcon />,
      title: 'click to go fullscreen',
      onClick: () => {
        setFullscreenFunc(true);
      },
    });
  }

  if (isMobile && !isLandscape) {
    carouselItems.push({
      el: <RotateYourDeviceIcon width={105} height={133} />,
      title: 'please rotate your device',
    });
  }

  return (
    <OverlayContainer>
      {showOrientationAlert && (
        <Alert>
          <div style={{ cursor: 'pointer' }}>
            <RotateYourDeviceIcon />
          </div>
          <div
            style={{
              fill: '#fff',
              fontSize: '1.2em',
              fontStyle: 'italic',
            }}
          >
            please rotate your device
          </div>
        </Alert>
      )}
      <BubbleSpeech
        link={openLink}
        title={openLinkTitle}
        type={openLinkType}
        onClose={clearOpenLink}
      />
      <OverlaySlice
        id="slice1"
        show={showSlice}
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: 'column',
          height: 'auto',
        }}
      >
        <Logo showLogo={mounted} />
        <Subtitle show={showHeading} />
      </OverlaySlice>
      <OverlaySlice
        id="slice2"
        show={showSlice}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          minHeight: '66px',
        }}
      >
        {(playerClosed || !!playerError.length) && (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ padding: 0, margin: '10px 30px' }}>
              {playerError ||
                'Your application has been stopped due to inactivity'}
            </p>
            <button
              style={{
                cursor: 'pointer',
                margin: '10px 0',
                fontFamily: 'Helvetica',
                borderRadius: '8px',
                fontSize: '1em',
                background: '#fff',
                padding: '6px 22px',
                color: '#000',
                textTransform: 'uppercase',
                fontWeight: 'normal',
              }}
              onClick={restartProcess}
            >
              Restart
            </button>
          </div>
        )}
        {showProgressBar && (
          <ProgressBar
            enabled={appProgress}
            progress={prepareEntry ? 100 : progress}
            maxOutText={PROGRESS_BAR_MAX_OUT_TEXT}
            texts={PROGRESS_BAR_TEXTS}
          />
        )}
        {showInputForm && (
          <InputForm
            inputRef={inputRef}
            checkboxRef={checkboxRef}
            checkboxFill={checkboxFill}
            onClick={sendEmailToSheets}
            checkbox={checkbox}
            onClickCheckbox={() => {
              setCheckbox(!checkbox);
            }}
          />
        )}
        {showPlayButton && load && (
          <div style={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
            <PlayButton show={load} onClick={startProcess} />
            <Fader show={load} style={{ justifyContent: 'center' }}>
              <h3
                style={{
                  margin: '16px 0',
                  fontSize: 'min(5vh, 8vw, 1.2em)',
                }}
              >
                Press play to start
              </h3>
            </Fader>
          </div>
        )}
        {showPlayButton && !load && <Spinner />}
      </OverlaySlice>
      <OverlaySlice
        id="slice3"
        show={showSlice}
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: 'column',
          height: 'auto',
        }}
      >
        {!playerReady && (
          <Fader show={showCarousel} opacityDelay={3}>
            <Carousel
              id="carousel"
              enabled={showCarousel}
              items={carouselItems}
              delay={5}
            />
          </Fader>
        )}
      </OverlaySlice>
      {showOrientationAlert && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 30,
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            minHeight: '23vh',
          }}
        >
          <Logo showLogo={showOrientationAlert} />
        </div>
      )}
      <OverlaySlice
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '2em',
          position: 'absolute',
          height: '100vh',
          textAlign: 'center',
          top: '0px',
        }}
        show={showWelcome}
      >
        Welcome to the Virtual Gallery
      </OverlaySlice>
    </OverlayContainer>
  );
}
