import React from 'react';

/* eslint-disable react/prop-types */
export default function LoginForm({
  loading,
  onSubmit,
  userRef,
  passRef,
  errorText,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
      }}
    >
      <div style={{ margin: '6px' }}>
        <p>Restricted area</p>
      </div>
      <div style={{ margin: '6px' }}>
        user <input ref={userRef} type="text" />
      </div>
      <div style={{ margin: '6px' }}>
        pass <input ref={passRef} type="password" />
      </div>
      <div style={{ margin: '6px' }}>
        <button
          style={{ fontSize: '1.2em' }}
          disabled={loading}
          type="button"
          onClick={onSubmit}
        >
          {loading ? 'loading...' : 'Login'}
        </button>
      </div>
      <div>
        {Boolean(errorText.length) && (
          <p style={{ color: 'red', textTransform: 'capitalize' }}>
            {errorText}
          </p>
        )}
      </div>
    </div>
  );
}
