import React, { useEffect, useState } from 'react';
import delay from 'lodash.delay';

/* eslint-disable react/prop-types */
export default function Carousel({
  id,
  items,
  enabled = false,
  delay: internalDelay = 3,
}) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (enabled) {
      delay(
        (val) => {
          setIndex(val);
        },
        internalDelay * 1000,
        index >= items.length - 1 ? 0 : index + 1
      );
    }
  }, [items, enabled, index]);

  const { el: LocalElement, title, onClick = () => {} } = items?.[index] || {};

  return (
    <div
      id={id}
      key={index}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        animation: 'fadeIn 2s',
        maxHeight: '142px',
        minHeight: '24px',
        height: '12vh',
        margin: '1em',
        animationIterationCount: 1,
      }}
    >
      {LocalElement && (
        <div style={{ cursor: 'pointer' }} onClick={onClick}>
          {LocalElement}
        </div>
      )}
      <div
        style={{
          marginLeft: 'min(3vw, 50px)',
          fill: '#fff',
          fontSize: 'min(3vh, 6vw, 1.2em)',
          fontStyle: 'italic',
        }}
      >
        {title}
      </div>
    </div>
  );
}
