import React from 'react';

/* eslint-disable react/prop-types */
export default function Fader({
  animate,
  show = false,
  style,
  children,
  opacityDelay = 0.5,
  rest,
}) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        transition: `opacity ${opacityDelay}s ease-out`,
        transform: 'scale(1)',
        animation: animate ? 'pulse 20s infinite' : '',
        opacity: show ? 1 : 0,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
