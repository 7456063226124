import React from 'react';

/* eslint-disable react/prop-types */
export default function AudioIcon({ size = 40 }) {
  return (
    <svg
      style={{
        maxHeight: `${size}px`,
        minHeight: '20px',
        height: '4vh',
      }}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Loading_audio"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle-Copy-22"
          stroke="#808080"
          fill="#404040"
          x="0.5"
          y="0.5"
          width="79"
          height="79"
          rx="8"
        ></rect>
        <g
          id="Group"
          transform="translate(26.000000, 22.000000)"
          stroke="#808080"
          strokeLinejoin="round"
          strokeWidth="4"
        >
          <path
            d="M25.4417143,-1.56339598 C26.1496168,-1.99370469 26.9649193,-2.09634194 27.7108233,-1.91442639 C28.4567273,-1.73251085 29.1332329,-1.2660425 29.5635416,-0.558140028 C29.8490206,-0.0884974424 30,0.4505436 30,1.00014566 L30,1.00014566 L30,35.441677 C30,36.2701042 29.6642136,37.0201042 29.1213203,37.5629974 C28.5784271,38.1058906 27.8284271,38.441677 27,38.441677 C26.4493081,38.441677 25.9092409,38.2900987 25.4389475,38.0035348 L25.4389475,38.0035348 L11.0276549,29.2220015 L2,29.2220253 C0.8954305,29.2220253 -0.1045695,28.77431 -0.828427125,28.0504524 C-1.55228475,27.3265948 -2,26.3265948 -2,25.2220253 L-2,25.2220253 L-2,11.2220253 C-2,10.1174558 -1.55228475,9.11745576 -0.828427125,8.39359813 C-0.1045695,7.66974051 0.8954305,7.22202526 1.99999471,7.22202526 L1.99999471,7.22202526 L10.9878522,7.22200148 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
