export const FURIOOS_CONTAINER = 'furioos-container';
export const FURIOOS_SDK_ID = 'pcP6PFqvC5iEdTMzX';
// export const NODE_SERVER_URL = 'http://localhost:8080';
export const NODE_SERVER_URL = 'https://virtual-server.colnaghi.com';
export const SUPERUSER_MODE = 'grantMeSecretPassage';
export const PROGRESS_BAR_TEXTS = [
  'Please wait a moment',
  'Highly detailed paintings loading',
  'Preparing interactive media',
  'Please wait a moment',
];

// export const PROGRESS_BAR_TEXTS = [
//   'Initiating load sequence',
//   'Setting up lobby',
//   'Preparing exhibition space',
//   'Loading videos',
//   'Setting up high resolution paintings',
// ];

export const PROGRESS_BAR_MAX_OUT_TEXT =
  'The virtual art gallery is currently at maximum capacity. Please wait until we have availability.';
