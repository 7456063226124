import { Player } from 'furioos-sdk';
import { FURIOOS_CONTAINER, FURIOOS_SDK_ID } from './constants';

export default class PlayerSingleton {
  static instance;

  static getInstance() {
    if (!PlayerSingleton.instance) {
      try {
        PlayerSingleton.instance = new Player(
          FURIOOS_SDK_ID,
          FURIOOS_CONTAINER,
          {
            whiteLabel: true,
            hideTitle: true,
            hideToolbar: true,
            hidePlayButton: false,
            debugAppMode: false,
          }
        );
      } catch (e) {
        console.log('Player SDK - browser not supported');
        PlayerSingleton.instance = 'ERROR';
      }
    }
    return PlayerSingleton.instance;
  }
}
