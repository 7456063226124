import delay from 'lodash.delay';
import React, { useCallback, useState } from 'react';

/* eslint-disable react/prop-types */
export default function PlayButton({ show = false, size = 96, onClick }) {
  const circleFill = '#333';
  const triangleFill = '#fff';
  const [circleOpacity, setCircleOpacity] = useState(1);
  const [circleScale, setCircleScale] = useState(1);

  const onHover = useCallback(() => {
    setCircleOpacity(0.8);
  }, []);

  const onHoverOut = useCallback(() => {
    setCircleOpacity(1);
  }, []);

  const onHoverDown = useCallback(() => {
    setCircleOpacity(1);
    setCircleScale(0.95);
  }, []);

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 58 58"
      style={{
        transition: 'opacity .5s ease-out',
        opacity: show ? 1 : 0,
        enableBackground: 'new 0 0 58 58',
        cursor: 'pointer',
        margin: '16px 0',
        width: 'auto',
        maxHeight: `${size}px`,
        minHeight: '48px',
        height: '8vh',
      }}
      xmlSpace="preserve"
      onMouseEnter={onHover}
      onMouseLeave={onHoverOut}
      onMouseDown={onHoverDown}
      onMouseUp={() => {
        setCircleScale(1);
        delay(() => {
          onClick();
        }, 300);
      }}
    >
      <g
        style={{
          transition: 'opacity .3s ease-in',
          opacity: circleOpacity,
          transformOrigin: 'center',
          transform: `scale(${circleScale})`,
        }}
      >
        <circle fill={circleFill} cx="29" cy="29" r="29" />
        <g>
          <polygon fill={triangleFill} points="44,29 22,44 22,29.273 22,14 	" />
          <path
            fill={triangleFill}
            d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
		c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
		l-22,15C22.394,44.941,22.197,45,22,45z M23,15.893v26.215L42.225,29L23,15.893z"
          />
        </g>
      </g>
    </svg>
  );
}
