import React from 'react';

/* eslint-disable react/prop-types */
export default function BubbleSpeech({
  title = '',
  link = '',
  type = 'CATALOGUE',
  onClose,
  style,
  rest,
}) {
  return (
    <div
      style={{
        position: 'absolute',
        transition: `opacity .5s ease-out`,
        transform: 'scale(1)',
        display: title.length && link.length ? 'block' : 'none',
        background: '#000',
        zIndex: '999',
        color: '#fff',
        borderRadius: '8px',
        width: '362px',
        left: 'calc(50vw - 177px)',
        padding: '10px',
        ...style,
      }}
      {...rest}
    >
      <div>
        <p
          style={{
            fontSize: '1.2em',
            fontWeight: 'bold',
            marginTop: 0,
            marginBottom: 0,
            textTransform: 'uppercase',
          }}
        >
          OPEN&nbsp;{type}
        </p>
        <p
          style={{
            fontSize: '1.2em',
            marginTop: 0,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </p>
        <p
          style={{
            fontSize: '0.9em',
          }}
        >
          This will open a new browser window with the selected{' '}
          {type.toLocaleLowerCase()} and close the experience
        </p>
      </div>
      <div>
        <button
          style={{
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '1em',
            background: '#fff',
            padding: '6px 22px',
            color: '#000',
            textTransform: 'uppercase',
            fontWeight: 'normal',
            borderStyle: 'solid',
            borderColor: '#fff',
          }}
          onClick={(e) => {
            e.preventDefault();
            onClose();
            try {
              setTimeout(() => {
                window.open(link, '_blank');
              }, 300);
            } catch (err) {
              console.warn('bubble failed to open tab', err);
            }
          }}
        >
          Open Link
        </button>
        <button
          style={{
            cursor: 'pointer',
            marginLeft: '12px',
            borderRadius: '8px',
            fontSize: '1em',
            background: '#000',
            padding: '6px 20px',
            color: '#fff',
            border: '1px solid #fff',
            textTransform: 'uppercase',
            fontWeight: 'normal',
          }}
          onClick={onClose}
        >
          Back to Exhibition
        </button>
      </div>
    </div>
  );
}
