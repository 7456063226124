import { useCallback, useEffect, useState } from 'react';

export default function useDeviceOrientation() {
  const mql = window?.matchMedia('(orientation: landscape)');
  const [orientation, setOrientation] = useState('landscape');

  const onOrientationChange = useCallback(
    (e) => {
      try {
        if (e.matches) setOrientation('landscape');
        else setOrientation('portrait');
      } catch (err) {
        console.warn('falling back due to', err);
        setOrientation('landscape');
      }
    },
    [setOrientation]
  );

  const onOrientationChangeFallback = useCallback(
    (e) => {
      try {
        if (e.target.screen.orientation.angle === 90)
          setOrientation('landscape');
        else setOrientation('portrait');
      } catch (err) {
        console.warn('falling back due to', err);
        setOrientation('landscape');
      }
    },
    [setOrientation]
  );

  useEffect(() => {
    try {
      mql.addEventListener('change', onOrientationChange);
    } catch (err) {
      console.warn('[mount]falling back to orientationchange', err);
      document?.addEventListener?.(
        'orientationchange',
        onOrientationChangeFallback
      );
    }

    return () => {
      try {
        mql.removeEventListener('change', onOrientationChange);
      } catch (err) {
        console.warn('[unmount]falling back to orientationchange', err);
        document?.removeEventListener?.(
          'orientationchange',
          onOrientationChangeFallback
        );
      }
    };
  }, [mql, onOrientationChange, onOrientationChangeFallback]);

  return {
    isLandscape: orientation === 'landscape',
    isPortrait: orientation === 'portrait',
  };
}
