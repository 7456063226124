import React from 'react';
import { displayNone, getFurioosContainer } from '../helpers';

export default function NotFound() {
  const mediaContainer = getFurioosContainer();
  displayNone(mediaContainer);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
      }}
    >
      <h1>404 | Page not found</h1>
      <p>
        return to <a href="/">homepage</a>
      </p>
    </div>
  );
}
