import React from 'react';

/* eslint-disable react/prop-types */
export default function ProgressBar({ progress, texts, maxOutText }) {
  if (!progress) return null;

  const isMaxedOut = progress === 100;

  const text = isMaxedOut
    ? maxOutText
    : texts?.[Math.floor((progress * texts.length - 1) / 100)];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '66px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'fadeIn 2s',
        animationIterationCount: 1,
      }}
    >
      <div
        style={{
          maxWidth: '400px',
          width: '96%',
          position: 'relative',
          height: 'auto',
        }}
      >
        <p style={{ textAlign: 'center', fontSize: 'min(3vh, 6vw, 1.2em)' }}>
          {text}
        </p>
        <div
          style={{
            position: 'absolute',
            maxWidth: '400px',
            width: '96%',
            height: '5px',
            background: '#333',
          }}
        />
        <div
          style={{
            position: 'absolute',
            transition: 'transform .5s ease-out',
            transform: `scaleX(${progress / 100})`,
            maxWidth: '400px',
            width: '96%',
            height: '5px',
            background: '#ccc',
            animation: isMaxedOut ? 'colorShift 60s ease-in-out infinite' : '',
          }}
        />
      </div>
    </div>
  );
}
