import React from 'react';

/* eslint-disable react/prop-types */
export default function RotateYourDeviceIcon({ width = 105, height = 133 }) {
  return (
    <svg
      style={{
        maxHeight: `${height}px`,
        maxWidth: `${width}px`,
        minHeight: '52.5px',
        minWidth: '67.25px',
        height: '12vh',
      }}
      viewBox="0 0 210 266"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Loading_rotate_your_device"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Phone-Copy"
          transform="translate(105.000000, 133.037782) rotate(-270.000000) translate(-105.000000, -133.037782) translate(55.500000, 32.037782)"
          stroke="#FFFFFF"
          strokeWidth="4"
        >
          <rect
            id="Rectangle"
            fill="#000000"
            x="-2"
            y="-2"
            width="103"
            height="206"
            rx="12"
          ></rect>
          <rect
            id="Rectangle-Copy-2"
            fill="#000000"
            x="7.5"
            y="28"
            width="84"
            height="146"
            rx="8"
          ></rect>
          <circle id="Oval" cx="50" cy="189" r="8"></circle>
        </g>
        <g
          id="Phone"
          transform="translate(55.500000, 32.037782)"
          stroke="#808080"
          strokeWidth="4"
        >
          <rect
            id="Rectangle"
            fill="#000000"
            x="-2"
            y="-2"
            width="103"
            height="206"
            rx="12"
          ></rect>
          <rect
            id="Rectangle-Copy-2"
            fill="#000000"
            x="7.5"
            y="28"
            width="84"
            height="146"
            rx="8"
          ></rect>
          <circle id="Oval" cx="50" cy="189" r="8"></circle>
        </g>
        <path
          id="Path-19"
          d="M192.953465,40.2915783 L193.109308,41.0844783 L193.137496,41.2885293 L194.184,53.465 L200.520668,39.5277438 C200.977752,38.5221853 202.163458,38.077559 203.169017,38.5346431 C204.126692,38.9699613 204.575584,40.0661612 204.221318,41.0382133 L204.162117,41.1829919 L194.666394,62.073031 C194.04327,63.4438675 192.204045,63.6473956 191.287478,62.4997615 L191.192902,62.3716221 L178.271301,43.4086392 C177.649309,42.4958411 177.885055,41.2516483 178.797853,40.6296569 C179.665011,40.038765 180.831253,40.2219806 181.478989,41.0243897 L181.576835,41.1562089 L190.198,53.809 L189.161,41.74 L189.036154,41.1007542 C184.221103,17.2352892 169.249535,5.65699305 143.573814,6.0075924 L142.784478,6.02205088 C141.680199,6.04738283 140.764468,5.17272345 140.739137,4.06844447 C140.713805,2.96416549 141.588464,2.04843489 142.692743,2.02310293 C170.75087,1.37945465 187.692635,14.2587309 192.953465,40.2915783 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        ></path>
        <path
          id="Path-19-Copy"
          d="M66.703465,242.291578 L66.8593081,243.084478 L66.8874959,243.288529 L67.934,255.465 L74.270668,241.527744 C74.7277521,240.522185 75.9134583,240.077559 76.9190168,240.534643 C77.8766915,240.969961 78.3255842,242.066161 77.9713179,243.038213 L77.9121174,243.182992 L68.4163939,264.073031 C67.7932698,265.443868 65.9540451,265.647396 65.0374776,264.499762 L64.942902,264.371622 L52.0213006,245.408639 C51.3993092,244.495841 51.6350546,243.251648 52.5478527,242.629657 C53.4150109,242.038765 54.5812528,242.221981 55.228989,243.02439 L55.326835,243.156209 L63.948,255.809 L62.911,243.74 L62.786154,243.100754 C57.9711034,219.235289 42.9995354,207.656993 17.3238138,208.007592 L16.534478,208.022051 C15.4301991,208.047383 14.5144685,207.172723 14.4891365,206.068444 C14.4638045,204.964165 15.3384639,204.048435 16.4427429,204.023103 C44.5008704,203.379455 61.4426351,216.258731 66.703465,242.291578 Z"
          transform="translate(41.488610, 234.000000) rotate(-180.000000) translate(-41.488610, -234.000000) "
          fill="#FFFFFF"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
}
