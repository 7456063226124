import React from 'react';

/* eslint-disable react/prop-types */
export default function Logo({ showLogo }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 446.4 72.2"
      xmlSpace="preserve"
      style={{
        maxWidth: '410px',
        marginTop: '16px',
        width: 'max(35vw, 42vh)',
        height: 'auto',
        minHeight: '50px',
        enableBackground: 'new 0 0 446.4 126.2',
        fill: '#fff',
      }}
    >
      <g
        id="container"
        style={{
          transition: 'opacity 2s ease-out',
          opacity: showLogo ? 1 : 0,
        }}
      >
        <g>
          <path
            d="M42.7,18.1c-0.8-1.3-1.8-2.5-3.1-3.4c-1.2-1-2.6-1.8-4.1-2.3c-1.5-0.6-3.2-0.8-4.8-0.8
                    c-3.1,0-5.7,0.6-7.9,1.8c-2.2,1.2-3.9,2.8-5.2,4.8c-1.3,2-2.3,4.3-2.9,6.9c-0.6,2.6-0.9,5.2-0.9,7.9c0,2.6,0.3,5.2,0.9,7.6
                    c0.6,2.5,1.6,4.7,2.9,6.7c1.3,2,3.1,3.6,5.2,4.8c2.2,1.2,4.8,1.8,7.9,1.8c4.2,0,7.5-1.3,9.8-3.8c2.4-2.6,3.8-5.9,4.3-10.1H58
                    c-0.3,3.9-1.3,7.4-2.7,10.6c-1.5,3.1-3.4,5.8-5.8,8c-2.4,2.2-5.2,3.9-8.4,5.1c-3.2,1.2-6.7,1.7-10.6,1.7c-4.8,0-9.1-0.8-12.9-2.5
                    c-3.8-1.7-7-3.9-9.6-6.9c-2.6-2.9-4.6-6.3-6-10.3C0.7,41.7,0,37.4,0,32.9c0-4.7,0.7-9,2.1-13c1.4-4,3.4-7.5,6-10.4
                    c2.6-3,5.8-5.3,9.6-7C21.6,0.8,25.9,0,30.6,0c3.4,0,6.7,0.5,9.7,1.5c3.1,1,5.8,2.4,8.2,4.3c2.4,1.9,4.4,4.2,6,7
                    c1.6,2.8,2.6,6,3,9.6H44.2C44,20.9,43.5,19.4,42.7,18.1z"
          ></path>
          <path
            d="M66.4,19.9c1.4-4,3.4-7.5,6-10.4c2.6-3,5.8-5.3,9.6-7C85.9,0.8,90.2,0,95,0c4.8,0,9.1,0.8,12.9,2.5
                    c3.8,1.7,7,4,9.6,7c2.6,3,4.6,6.4,6,10.4c1.4,4,2.1,8.3,2.1,13c0,4.5-0.7,8.8-2.1,12.7c-1.4,3.9-3.4,7.3-6,10.3
                    c-2.6,2.9-5.8,5.2-9.6,6.9c-3.8,1.7-8.1,2.5-12.9,2.5c-4.8,0-9.1-0.8-12.9-2.5c-3.8-1.7-7-3.9-9.6-6.9c-2.6-2.9-4.6-6.3-6-10.3
                    s-2.1-8.2-2.1-12.7C64.3,28.2,65,23.9,66.4,19.9z M79,40.5c0.6,2.5,1.6,4.7,2.9,6.7c1.3,2,3.1,3.6,5.2,4.8
                    c2.2,1.2,4.8,1.8,7.9,1.8c3.1,0,5.7-0.6,7.9-1.8c2.2-1.2,3.9-2.8,5.2-4.8c1.3-2,2.3-4.2,2.9-6.7c0.6-2.5,0.9-5,0.9-7.6
                    c0-2.7-0.3-5.4-0.9-7.9c-0.6-2.6-1.6-4.8-2.9-6.9c-1.3-2-3.1-3.6-5.2-4.8c-2.2-1.2-4.8-1.8-7.9-1.8c-3.1,0-5.7,0.6-7.9,1.8
                    c-2.2,1.2-3.9,2.8-5.2,4.8c-1.3,2-2.3,4.3-2.9,6.9c-0.6,2.6-0.9,5.2-0.9,7.9C78,35.5,78.3,38.1,79,40.5z"
          ></path>
          <path d="M147,1.5v50.8h30.4v11.5h-44.1V1.5H147z"></path>
          <path d="M198.5,1.5l26,41.8h0.2V1.5h12.8v62.3h-13.7l-25.9-41.7h-0.2v41.7h-12.8V1.5H198.5z"></path>
          <path
            d="M280.9,1.5l23.3,62.3H290l-4.7-13.9H262l-4.9,13.9h-13.8l23.6-62.3H280.9z M281.7,39.7l-7.9-22.9h-0.2
                    l-8.1,22.9H281.7z"
          ></path>
          <path
            d="M343.8,63.3c-3,1.3-6,1.9-9,1.9c-4.8,0-9.1-0.8-12.9-2.5c-3.8-1.7-7-3.9-9.6-6.9c-2.6-2.9-4.6-6.3-6-10.3
                    s-2.1-8.2-2.1-12.7c0-4.7,0.7-9,2.1-13c1.4-4,3.4-7.5,6-10.4c2.6-3,5.8-5.3,9.6-7c3.8-1.7,8.1-2.5,12.9-2.5
                    c3.2,0,6.3,0.5,9.3,1.4c3,1,5.7,2.4,8.1,4.2c2.4,1.9,4.4,4.2,6,6.9c1.6,2.7,2.5,5.9,2.9,9.4H348c-0.8-3.5-2.4-6.1-4.7-7.9
                    c-2.3-1.7-5.1-2.6-8.5-2.6c-3.1,0-5.7,0.6-7.9,1.8c-2.2,1.2-3.9,2.8-5.2,4.8c-1.3,2-2.3,4.3-2.9,6.9c-0.6,2.6-0.9,5.2-0.9,7.9
                    c0,2.6,0.3,5.2,0.9,7.6c0.6,2.5,1.6,4.7,2.9,6.7c1.3,2,3.1,3.6,5.2,4.8c2.2,1.2,4.8,1.8,7.9,1.8c4.5,0,8-1.1,10.5-3.4
                    c2.5-2.3,3.9-5.6,4.3-10h-13.8V30.1h26.2v33.7h-8.7l-1.4-7.1C349.5,59.9,346.8,62.1,343.8,63.3z"
          ></path>
          <path d="M384.4,1.5v23.9h25.2V1.5h13.7v62.3h-13.7V36.9h-25.2v26.9h-13.7V1.5H384.4z"></path>
          <path d="M446.4,1.5v62.3h-13.7V1.5H446.4z"></path>
        </g>
      </g>
    </svg>
  );
}
