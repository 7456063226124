import React, { useCallback, useRef, useState } from 'react';
import { NODE_SERVER_URL } from '../constants';
import { displayNone, getFurioosContainer } from '../helpers';
import LoginForm from './LoginForm';

export default function Setup() {
  const mediaContainer = getFurioosContainer();
  displayNone(mediaContainer);

  const userRef = useRef(null);
  const passRef = useRef(null);

  const fileRef = useRef(null);
  const documentIdRef = useRef(null);
  const [used, setUsed] = useState(false);
  const [error, setError] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(false);

  const onLogin = useCallback(async () => {
    const username = userRef.current.value;
    const password = passRef.current.value;
    setLoading(true);
    try {
      const response = await fetch(`${NODE_SERVER_URL}/login`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ username, password }),
      });
      if (response.status !== 200) {
        const out = await response.json();
        setErrorText(out || 'Invalid credentials');
      } else {
        setErrorText('');
        setIsLogged(true);
      }
      console.log('response', response);
    } catch (e) {
      setErrorText(
        'Request cannot be completed at this time. Try later or contact administrator.'
      );
      console.log('api did not response');
    }
    setLoading(false);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const documentId = documentIdRef.current.value;
    const file = fileRef.current.files[0];
    if (!file || !documentId) return;
    const formData = new FormData();
    formData.append('credentials', file);

    try {
      await fetch(`${NODE_SERVER_URL}/update-document-id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        mode: 'cors',
        body: JSON.stringify({ documentId }),
      });

      await fetch(`${NODE_SERVER_URL}/upload-creds`, {
        method: 'POST',
        mode: 'cors',
        body: formData,
      });

      setUsed(true);
    } catch (err) {
      console.log('could not update creds', err);
      setUsed(false);
      setError(JSON.stringify(err));
    }
  };

  if (!isLogged)
    return (
      <LoginForm
        loading={loading}
        onSubmit={onLogin}
        userRef={userRef}
        passRef={passRef}
        errorText={errorText}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
      }}
    >
      {!used ? (
        <form onSubmit={onSubmit} encType="multipart/form-data">
          <div>
            <h2>Submit Google Sheet API credentials</h2>
          </div>
          <div
            style={{ background: '#222', padding: '6px', marginBottom: '16px' }}
          >
            <p>Google Document Spreadsheet ID</p>
            <input size="40" type="text" ref={documentIdRef} />
          </div>
          <div
            style={{ background: '#222', padding: '6px', marginBottom: '16px' }}
          >
            <p>credentials.json</p>
            <input type="file" ref={fileRef} />
          </div>
          <div style={{ padding: '6px' }}>
            <button
              type="submit"
              style={{
                fontSize: '1.2em',
              }}
            >
              Submit
            </button>
          </div>
        </form>
      ) : (
        <div>
          <p style={{ color: 'green' }}>Setup completed succesfully!</p>
        </div>
      )}

      {error && (
        <div>
          <p style={{ color: 'red' }}>{error}</p>
        </div>
      )}
    </div>
  );
}
