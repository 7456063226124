import delay from 'lodash.delay';
import React, { useState } from 'react';

/* eslint-disable react/prop-types */
export default function OverlaySlice({
  id,
  children,
  style,
  show = true,
  rest,
}) {
  const [hideAfter, setHideAfter] = useState(false);
  if (!show) {
    delay(() => {
      setHideAfter(true);
    }, 1000);
  }
  return (
    <div
      id={id}
      style={{
        transition: 'opacity 1s ease-out',
        display: 'flex',
        background: '#000',
        zIndex: 10,
        height: '40%',
        width: '100%',
        opacity: show ? 1 : 0,
        visibility: hideAfter && !show ? 'hidden' : 'visible',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
