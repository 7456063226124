import { useCallback, useEffect, useState } from 'react';

export default function useSdkMessage({ player }) {
  const [playerError, setPlayerError] = useState('');
  const [playerClosed, setPlayerClosed] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [openLink, setOpenLink] = useState('');
  const [openLinkTitle, setOpenLinkTitle] = useState('');
  const [openLinkType, setOpenLinkType] = useState('');
  const [fullscreen, setFullscreen] = useState(false);

  const setFullscreenFunc = useCallback(
    (status) => {
      if (status) {
        try {
          setFullscreen(true);
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
          } else if (document.body.webkitRequestFullscreen) {
            /* Safari */
            document.body.webkitRequestFullscreen();
          } else if (document.body.msRequestFullscreen) {
            /* IE11 */
            document.body.msRequestFullscreen();
          }
          player.maximize();
        } catch (e) {
          console.warn('useSdkMessage.setFullscreenFunc.maximize', e);
          setFullscreen(fullscreen);
        }
      } else if (!status) {
        try {
          setFullscreen(false);
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
          }
          player.minimize();
        } catch (e) {
          console.warn('useSdkMessage.setFullscreenFunc.minimize', e);
          setFullscreen(true);
        }
      }
    },
    [player]
  );

  const clearOpenLink = useCallback(() => {
    setOpenLink('');
    setOpenLinkTitle('');
  }, [setOpenLink, setOpenLinkTitle]);

  useEffect(() => {
    if (player === 'ERROR') {
      setPlayerError('Could not be started at this time, try later');
      setPlayerClosed(true);
      setPlayerReady(false);
      return;
    }
    // sdk
    player.onSDKMessage((data) => {
      console.log('useSdkMessage', data);
      const splitData = data.split(':');
      const command = splitData?.[0];
      const commandData = splitData?.[1];
      const commandText = splitData?.[2];
      const commandType = splitData?.[3];
      const status = commandData === 'True';
      switch (command) {
        case 'OpenWebLink':
          setOpenLink(decodeURIComponent(commandData));
          setOpenLinkTitle(commandText);
          setOpenLinkType(commandType);
          break;
        case 'FullScreen':
          setFullscreenFunc(status);
          break;
        case 'Player':
          if (commandData === 'close') {
            setPlayerClosed(true);
          } else if (commandData === 'ready') {
            setPlayerReady(true);
          }
          break;
        default:
          console.warn(
            `useSdkMessage.Comand not found "${JSON.stringify(data)}"`
          );
          break;
      }
    });
  }, [player]);

  return {
    playerReady,
    playerError,
    playerClosed,
    openLink,
    openLinkTitle,
    openLinkType,
    setFullscreenFunc,
    fullscreen,
    clearOpenLink,
  };
}
