import React from 'react';

/* eslint-disable react/prop-types */
export default function FullscreenIcon({ size = 40 }) {
  return (
    <svg
      style={{
        maxHeight: `${size}px`,
        minHeight: '20px',
        height: '4vh',
      }}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Loading_fullscreen"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle-Copy-22"
          stroke="#808080"
          fill="#404040"
          x="0.5"
          y="0.5"
          width="79"
          height="79"
          rx="8"
        ></rect>
        <g
          id="FullScreen-Copy-2"
          transform="translate(20.000000, 20.000000)"
          stroke="#808080"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        >
          <polyline id="Path-5" points="0 13 0 0 14 0"></polyline>
          <polyline id="Path-6" points="26 0 40 0 40 13"></polyline>
          <polyline id="Path-7" points="40 27 40 40 26 40"></polyline>
          <polyline id="Path-8" points="14 40 0 40 0 27"></polyline>
        </g>
      </g>
    </svg>
  );
}
