import React from 'react';

/* eslint-disable react/prop-types */
export default function Subtitle({ show }) {
  return (
    <div
      style={{
        display: 'flex',
        transition: 'opacity 2s ease-out',
        opacity: show ? 1 : 0,
      }}
    >
      <h3
        style={{
          fontFamily: 'Arial',
          wordSpacing: '8px',
          letterSpacing: '-1px',
          color: '#fff',
          textTransform: 'uppercase',
          fontSize: 'min(4.3vh, 5vw, 1.2em)',
          width: '100%',
          textAlign: 'center',
          paddingTop: '12px',
          margin: '0px',
        }}
      >
        Virtual Art Gallery
      </h3>
    </div>
  );
}
