import React, { useEffect, useState } from 'react';
import isEmail from '../predicates/isEmail';
import Checkbox from './Checkbox';

/* eslint-disable react/prop-types */
export default function InputForm({
  onClick,
  style,
  rest,
  inputRef,
  checkbox,
  onClickCheckbox,
  checkboxFill = '#fff',
}) {
  const [startedTyping, setStartedTyping] = useState(false);
  let t;

  const onUserType = () => {
    if (!startedTyping) setStartedTyping(true);
    clearTimeout(t);
    t = setTimeout(() => {
      const { value } = inputRef.current;
      if (!value) {
        /* eslint-disable no-param-reassign */
        inputRef.current.style.borderColor = 'white';
        return;
      }
      if (!isEmail(value)) {
        /* eslint-disable no-param-reassign */
        inputRef.current.style.borderColor = 'red';
        return;
      }
      /* eslint-disable no-param-reassign */
      inputRef.current.style.borderColor = 'green';
    }, 300);
  };

  useEffect(
    () => () => {
      clearTimeout(t);
    },
    []
  );

  return (
    <div
      style={{
        justifyContent: 'space-around',
        flexFlow: 'column',
        alignItems: 'center',
        display: 'flex',
        padding: '10px',
        bottom: 0,
        zIndex: 20,
        ...style,
      }}
      {...rest}
    >
      <input
        ref={inputRef}
        formNoValidate="formnovalidate"
        onChange={onUserType}
        placeholder="someone@email.com"
        style={{
          width: '100%',
          maxWidth: '300px',
          fontSize: '1em',
          padding: '6px',
          marginBottom: '12px',
          minWidth: '240px',
          background: 'none',
          border: '0',
          borderBottom: '1px solid #fff',
          outline: 'none',
          color: '#fff',
        }}
        type="text"
        name="email"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Checkbox
          fill={checkboxFill}
          checked={checkbox}
          onClick={onClickCheckbox}
        />
        <p style={{ padding: '10px', margin: 0 }}>
          <span onClick={onClickCheckbox}>I agree with the&nbsp;</span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.colnaghi.com/terms-conditions"
          >
            Terms &amp; Conditions
          </a>
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: 'min(2.5vh, 5vw, 1em)',
            fontStyle: 'italic',
            marginBottom: '12px',
          }}
        >
          please enter your email to
        </p>
      </div>
      <button
        style={{
          borderRadius: '8px',
          cursor: 'pointer',
          fontSize: '1.1em',
          background: '#fff',
          padding: '6px 22px',
          color: '#000',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          outline: 'none',
          border: '0',
          opacity: startedTyping ? 1 : 0.25,
        }}
        onClick={onClick}
        disabled={!startedTyping}
      >
        Start Exploring
      </button>
    </div>
  );
}
