import React from 'react';

/* eslint-disable react/prop-types */
export default function OverlayContainer({ children, style, rest }) {
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        minHeight: '100vh',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-evenly',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
