import React from 'react';

/* eslint-disable react/prop-types */
export default function Spinner({ size = 50 }) {
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <svg
        id="spinner"
        width={`${size}px`}
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          animation: 'rotate 4s linear infinite',
          zIndex: '2',
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-25px 0 0 -25px',
        }}
      >
        <circle
          style={{
            stroke: '#333',
            strokeLinecap: 'round',
            animation: 'dash 3s ease-in-out infinite',
          }}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </div>
  );
}
