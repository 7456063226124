import React from 'react';

/* eslint-disable react/prop-types */
export default function Alert({ style, children, rest }) {
  return (
    <div
      style={{
        background: '#232323',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderTop: '1px solid #333',
        display: 'flex',
        height: '156px',
        width: '100vw',
        position: 'absolute',
        bottom: 0,
        zIndex: 20,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
